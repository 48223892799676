import { gsap } from "gsap";

gsap.config({
  nullTargetWarn: false,
});

jQuery(function ($) {
  const $cursor = $(".cursor");
  const $cursorBg = $(".cursor_bg");

  $(document).on("mousemove", function (e) {
    gsap.set($cursor, { x: e.clientX, y: e.clientY });
    gsap.to($cursorBg, 0.4, { x: e.clientX, y: e.clientY });
  });

  const $linkSelector = "a, button, .mfp-close";

  $(document).on(
    {
      mouseenter: function () {
        $cursor.addClass("link");
        $cursorBg.addClass("link");
      },
      mouseleave: function () {
        $cursor.removeClass("link");
        $cursorBg.removeClass("link");
      },
    },
    $linkSelector
  );

  $(".section-movie-fv__link").on({
    mouseenter: function () {
      $cursor.addClass("movie");
      $cursorBg.addClass("movie");
    },
    mouseleave: function () {
      $cursor.removeClass("movie");
      $cursorBg.removeClass("movie");
    },
  });
});
