import "magnific-popup";

$(function () {
  $(".seciton-modal .row").magnificPopup({
    delegate: "a",
    type: "image",
    gallery: {
      // ギャラリー表示にする
      enabled: true,
    },
  });

  $(".movie-popup-link").magnificPopup({
    type: "iframe",
    iframe: {
      markup:
        '<div class="mfp-movie-container mfp-iframe-scaler">' +
        '<div class="mfp-close"></div>' +
        '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
        '<a href="/archive" class="archive-link">Archiveを見る</a>' +
        "</div>",
    },
  });

  $("[modal-youtube]").magnificPopup({
    type: "iframe",
    mainClass: "mfp-fade",
    removalDelay: 200,
    preloader: false,
    fixedContentPos: false,
  });

  $("[inline-modal]").magnificPopup({
    type: "inline",
    preloader: false,
  });
});
