/*
 * menu-trigger btn
 * ----------------------------------------------- */
jQuery(function ($) {
  var $menuTrigger = $(".menu-trigger");

  $menuTrigger.on("click", function (e) {
    e.preventDefault();

    if ($menuTrigger.attr("aria-expanded") === "true") {
      $(".navbar-simple").removeClass("active");
    } else {
      $(".navbar-simple").addClass("active");
    }
  });

  $.each($menuTrigger, function (i) {
    var $this = $(this);
    var id = $this.attr("data-target");

    $(id).on("show.bs.collapse hide.bs.collapse", function (e) {
      if ("#" + $(e.target).attr("id") === id) {
        $this.toggleClass("active");
      }
    });
  });
});

jQuery(function ($) {
  $(".movie-menu-trigger").on("click", function () {
    $(this).toggleClass("active");
    $(".movie-menu").fadeToggle();
  });
});
