$(window).on("load", function () {
  $("body").removeClass("preloading");
  $(".loader")
    .delay(1500)
    .fadeOut(function () {
      $(function () {
        $("#covid-modal").modal();

        setTimeout(function () {
          document
            .querySelectorAll(".section-new-fv__video video")
            .forEach(function (element) {
              element.addEventListener("ended", function () {
                $(".section-new-fv__logo").fadeIn();
                setTimeout(function () {
                  $(".section-new-fv__logo2").fadeIn();
                }, 500);
              });
              element.play();
            });
        }, 0);
      });
    });
  $(".navbar-simple").removeClass("disable");
});
