import "bootstrap-sass";
import "jquery-match-height";
import "jquery.easing";

import "./modules/webfont";
// import "./modules/page-top";
// import "./modules/jqueryvalidation";
import "./modules/menu-trigger";
// import "./modules/bs.tab";
// import "./modules/js-header";
// import "./modules/js-footer";
// import "./modules/waypoints";
// import "./modules/dropdown-hover";
import "./modules/smoothScroll";
import "./modules/page-loader";
// import "./modules/swiper-fv";
import "./modules/aos";
import "./modules/close-collapse";
import "./modules/vh";

// import ScrollMagic from "scrollmagic";

import "./modules/magnific-popup";
import "./modules/parallax";
import "./modules/modal";
import "./modules/vivus";
import "./modules/gsap";
