import Vivus, { LINEAR } from "vivus";

jQuery(function ($) {
  setTimeout(function () {
    var fvTitle = new Vivus(
      "movie-fv-title",
      {
        type: "oneByOne",
        duration: 100,
        pathTimingFunction: LINEAR,
        onReady: function () {
          $("#movie-fv-title").addClass("active");
        },
      },
      function () {
        $(".section-movie-fv__title-ja").addClass("active");
      }
    );

    var fvTitleSp = new Vivus(
      "movie-fv-title-sp",
      {
        type: "oneByOne",
        duration: 100,
        pathTimingFunction: LINEAR,
        onReady: function () {
          $("#movie-fv-title-sp").addClass("active");
        },
      },
      function () {
        $(".section-movie-fv__title-ja").addClass("active");
      }
    );
  }, 750);
});
