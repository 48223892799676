import { isMobile } from "./isMobile";

/*
 *
 * ----------------------------------------------- */
jQuery(function ($) {
  $("a").on("click", function (event) {
    if (isMobile) {
      if (this.pathname === window.location.pathname) {
        $(".navbar-collapse").collapse("hide");
        $(".navbar-toggle").removeClass("toggle");
      }
    }
  });
});
