import "jquery-smooth-scroll";
import { isMobile } from "./isMobile";

/*
 *
 * ----------------------------------------------- */
jQuery(function ($) {
  if (typeof $.smoothScroll !== "function") {
    return false;
  }

  var reSmooth = /^#sm-/;
  var id;

  $(window).on("load", function () {
    if (reSmooth.test(location.hash)) {
      id = "#" + location.hash.replace(reSmooth, "");

      var offset = isMobile() ? -75 : -85;

      var $id = $(id);
      var offsetSm = $id.data("offset-sm");
      var offsetMd = $id.data("offset-md");

      if (isMobile() && offsetSm) {
        offset = offsetSm;
      } else if (offsetMd) {
        offset = offsetMd;
      }

      $.smoothScroll({
        scrollTarget: id,
        offset: offset,
        easing: "easeInOutCubic",
      });
    }
  });
});

/*
 *
 * ----------------------------------------------- */
// jQuery Smooth Scroll - v2.2.0 - 2017-05-05
// https://github.com/kswedberg/jquery-smooth-scroll
jQuery(function ($) {
  $("[data-sm]").smoothScroll({
    offset: -85,
    beforeScroll: function (e) {
      var scrollTarget = e.scrollTarget;

      if (scrollTarget.search("scroll-active")) {
        $(".scroll-active").removeClass("active");
        $(scrollTarget).addClass("active");
      }

      if (isMobile()) {
        e.offset = -75;
      }
    },
  });

  if (isMobile()) {
    $(".nav a").on("click", function () {
      $(".navbar-toggle").click();
    });
  }
});
